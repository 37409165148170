import React, { useEffect, PureComponent, useState } from 'react'
import axios from 'axios'
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormCheck,
} from '@coreui/react'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
} from '@coreui/icons'
import './dash.css'
import WidgetsBrand from '../widgets/WidgetsBrand'
import WidgetsDropdown from '../widgets/WidgetsDropdown'
import { AppHeader, AppSidebar } from 'src/components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment/moment'

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const token = localStorage.getItem('jwtToken')
  const [loading, setLoading] = useState(false)
  const [label, setLabel] = useState([])
  const [data, setData] = useState([])
  const token1 = localStorage.getItem('jwtToken')
  const [loading1, setLoading1] = useState(false)
  const [label1, setLabel1] = useState([])
  const [loginData, setLoginData] = useState([])
  const token2 = localStorage.getItem('jwtToken')
  const [loading2, setLoading2] = useState(false)
  const [label2, setLabel2] = useState([])
  const [testCount, setTestCount] = useState([])
  const [testData, setTestData] = useState([])
  const [complete, setComplete] = useState('completed')
  const token3 = localStorage.getItem('jwtToken')
  const [loading3, setLoading3] = useState(false)
  const [label3, setLabel3] = useState([])
  const [startedTest, setStartedTest] = useState([])
  const [completedTest, setCompletedTest] = useState([])
  const [completeTest, setCompleteTest] = useState('FULL')
  const [loading4, setLoading4] = useState(false)
  const [label4, setLabel4] = useState([])
  const [subData, setSubData] = useState([])

  useEffect(() => {
    handelDaily()
  }, [])

  const handelDaily = (e) => {
    setLoading(true)
    axios
      .get(`https://api.testbot.in/admin/get/signup/users?type=weekly`, {
        headers: {
          'X-Custom-Token':
            'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
        },
      })
      .then((res) => {
        console.log(res)
        let labelfromapi = []
        let datefromapi = []

        res.data.data.signupData.map((res) => {
          labelfromapi.push(res.date)
          datefromapi.push(res.data)
        })

        setLabel(labelfromapi)
        setData(datefromapi)
        setLoading(false)
        console.log(labelfromapi)
      })
  }

  const handelMonthly = (e) => {
    setLoading(true)
    axios
      .get(`https://api.testbot.in/admin/get/signup/users?type=monthly`, {
        headers: {
          'X-Custom-Token':
            'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
        },
      })
      .then((res) => {
        console.log(res)
        let labelfromapi = []
        let datefromapi = []

        res.data.data.signupData.map((res) => {
          labelfromapi.push(res.date)
          datefromapi.push(res.data)
        })

        setLabel(labelfromapi)
        setData(datefromapi)
        setLoading(false)
        console.log(labelfromapi)
      })
  }

  const handelYearly = (e) => {
    setLoading(true)
    axios
      .get(`https://api.testbot.in/admin/get/signup/users?type=yearly`, {
        headers: {
          'X-Custom-Token':
            'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
        },
      })
      .then((res) => {
        console.log(res)
        let labelfromapi = []
        let datefromapi = []

        res.data.data.signupData.map((res) => {
          labelfromapi.push(res.date)
          datefromapi.push(res.data)
        })

        setLabel(labelfromapi)
        setData(datefromapi)
        setLoading(false)
        console.log(labelfromapi)
      })
  }

  useEffect(() => {
    handelWeekly()
  }, [])

  const handelWeekly = (e) => {
    setLoading1(true)
    axios
      .get(`https://api.testbot.in/admin/get/userDailyLogin?type=weekly`, {
        headers: {
          'X-Custom-Token':
            'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
        },
      })
      .then((res) => {
        console.log(res)
        let label1fromapi = []
        let loginDatafromapi = []

        res.data.data.loginData.map((res) => {
          label1fromapi.push(res.date)
          loginDatafromapi.push(res.data)
        })

        setLabel1(label1fromapi)
        setLoginData(loginDatafromapi)
        setLoading1(false)
      })
  }

  const handelMonthlyLogin = (e) => {
    setLoading1(true)
    axios
      .get(`https://api.testbot.in/admin/get/userDailyLogin?type=monthly`, {
        headers: {
          'X-Custom-Token':
            'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
        },
      })
      .then((res) => {
        console.log(res)
        let label1fromapi = []
        let loginDatafromapi = []

        res.data.data.loginData.map((res) => {
          label1fromapi.push(res.date)
          loginDatafromapi.push(res.data)
        })

        setLabel1(label1fromapi)
        setLoginData(loginDatafromapi)
        setLoading1(false)
      })
  }

  const handelYearlyLogin = (e) => {
    setLoading1(true)
    axios
      .get(`https://api.testbot.in/admin/get/userDailyLogin?type=monthly`, {
        headers: {
          'X-Custom-Token':
            'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
        },
      })
      .then((res) => {
        console.log(res)
        let label1fromapi = []
        let loginDatafromapi = []

        res.data.data.loginData.map((res) => {
          label1fromapi.push(res.date)
          loginDatafromapi.push(res.data)
        })

        setLabel1(label1fromapi)
        setLoginData(loginDatafromapi)
        setLoading1(false)
      })
  }

  useEffect(() => {
    handelComplete()
  }, [complete])

  const handelComplete = (e) => {
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    setLoading2(true)
    axios
      .get(
        `https://api.testbot.in/admin/get/daily/testCount?from=${sdate}%20&to=${edate}&type=${complete}`,
        {
          headers: {
            'X-Custom-Token':
              'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
          },
        },
      )
      .then((res) => {
        console.log(res)
        let label2fromapi = []
        let testCountfromapi = []
        let testDatafromapi = []

        res.data.data.list.map((res) => {
          label2fromapi.push(res.date)
          testCountfromapi.push(res.testCount)
          testDatafromapi.push(res.uniqueUserCount)
        })

        setLabel2(label2fromapi)
        setTestCount(testCountfromapi)
        setTestData(testDatafromapi)
        setLoading2(false)
      })
  }

  const handelsubmit = () => {
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    console.log(endDate)
    console.log(startDate)
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(
        `https://api.testbot.in/admin/get/daily/testCount?from=${sdate}%20&to=${edate}&type=${complete}`,
        {
          headers: {
            'X-Custom-Token':
              'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
          },
        },
      )
      .then((res) => {
        console.log(res)
        let label2fromapi = []
        let testCountfromapi = []
        let testDatafromapi = []

        res.data.data.list.map((res) => {
          label2fromapi.push(res.date)
          testCountfromapi.push(res.testCount)
          testDatafromapi.push(res.uniqueUserCount)
        })

        setLabel2(label2fromapi)
        setTestCount(testCountfromapi)
        setTestData(testDatafromapi)
        setLoading2(false)
      })
  }

  const handelIncomplete = (e) => {
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    console.log(endDate)
    console.log(startDate)
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    setLoading2(true)
    axios
      .get(
        `https://api.testbot.in/admin/get/daily/testCount?from=${sdate}%20&to=${edate}&type=started`,
        {
          headers: {
            'X-Custom-Token':
              'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
          },
        },
      )
      .then((res) => {
        console.log(res)
        let label2fromapi = []
        let testCountfromapi = []
        let testDatafromapi = []

        res.data.data.list.map((res) => {
          label2fromapi.push(res.date)
          testCountfromapi.push(res.testCount)
          testDatafromapi.push(res.uniqueUserCount)
        })

        setLabel2(label2fromapi)
        setTestCount(testCountfromapi)
        setTestData(testDatafromapi)
        setLoading2(false)
      })
  }

  useEffect(() => {
    handelFull()
  }, [completeTest])

  const handelFull = (e) => {
    let stdate = moment(startDate).format('L').split('/')
    let endate = moment(endDate).format('L').split('/')
    console.log(endDate)
    console.log(startDate)
    stdate = stdate[2] + '-' + stdate[0] + '-' + stdate[1]
    endate = endate[2] + '-' + endate[0] + '-' + endate[1]
    setLoading3(true)
    axios
      .get(
        `https://api.testbot.in/admin/count/testData?from=${stdate}%20&to=${endate}&testType=${completeTest}`,
        {
          headers: {
            'X-Custom-Token':
              'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
          },
        },
      )
      .then((res) => {
        console.log(res)
        let label3fromapi = []
        let startedTestfromapi = []
        let completedTestfromapi = []

        res.data.data.testCount.map((res) => {
          label3fromapi.push(res.date)
          startedTestfromapi.push(res.startedTest)
          completedTestfromapi.push(res.completedTest)
        })
        setLabel3(label3fromapi)
        setCompletedTest(completedTestfromapi)
        setStartedTest(startedTestfromapi)
        setLoading3(false)
      })
  }
  const handelRecommended = (e) => {
    let stdate = moment(startDate).format('L').split('/')
    let endate = moment(endDate).format('L').split('/')
    console.log(endDate)
    console.log(startDate)
    stdate = stdate[2] + '-' + stdate[0] + '-' + stdate[1]
    endate = endate[2] + '-' + endate[0] + '-' + endate[1]
    setLoading3(true)
    setLoading3(true)
    axios
      .get(
        `https://api.testbot.in/admin/count/testData?from=${stdate}%20&to=${endate}&testType=RECOMMENDED`,
        {
          headers: {
            'X-Custom-Token':
              'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
          },
        },
      )
      .then((res) => {
        console.log(res)
        let label3fromapi = []
        let startedTestfromapi = []
        let completedTestfromapi = []

        res.data.data.testCount.map((res) => {
          label3fromapi.push(res.date)
          startedTestfromapi.push(res.startedTest)
          completedTestfromapi.push(res.completedTest)
        })
        setLabel3(label3fromapi)
        setCompletedTest(completedTestfromapi)
        setStartedTest(startedTestfromapi)
        setLoading3(false)
      })
  }

  const handelCustom = (e) => {
    let stdate = moment(startDate).format('L').split('/')
    let endate = moment(endDate).format('L').split('/')
    console.log(endDate)
    console.log(startDate)
    stdate = stdate[2] + '-' + stdate[0] + '-' + stdate[1]
    endate = endate[2] + '-' + endate[0] + '-' + endate[1]
    setLoading3(true)
    setLoading3(true)
    axios
      .get(
        `https://api.testbot.in/admin/count/testData?from=${stdate}%20&to=${endate}&testType=CUSTOM`,
        {
          headers: {
            'X-Custom-Token':
              'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
          },
        },
      )
      .then((res) => {
        console.log(res)
        let label3fromapi = []
        let startedTestfromapi = []
        let completedTestfromapi = []

        res.data.data.testCount.map((res) => {
          label3fromapi.push(res.date)
          startedTestfromapi.push(res.startedTest)
          completedTestfromapi.push(res.completedTest)
        })
        setLabel3(label3fromapi)
        setCompletedTest(completedTestfromapi)
        setStartedTest(startedTestfromapi)
        setLoading3(false)
      })
  }

  const handelsubmit1 = () => {
    let stdate = moment(startDate).format('L').split('/')
    let endate = moment(endDate).format('L').split('/')
    console.log(endDate)
    console.log(startDate)
    stdate = stdate[2] + '-' + stdate[0] + '-' + stdate[1]
    endate = endate[2] + '-' + endate[0] + '-' + endate[1]
    axios
      .get(
        `https://api.testbot.in/admin/count/testData?from=${stdate}%20&to=${endate}&testType=${completeTest}`,
        {
          headers: {
            'X-Custom-Token':
              'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
          },
        },
      )
      .then((res) => {
        console.log(res)
        let label3fromapi = []
        let startedTestfromapi = []
        let completedTestfromapi = []

        res.data.data.testCount.map((res) => {
          label3fromapi.push(res.date)
          startedTestfromapi.push(res.startedTest)
          completedTestfromapi.push(res.completedTest)
        })
        setLabel3(label3fromapi)
        setCompletedTest(completedTestfromapi)
        setStartedTest(startedTestfromapi)
        setLoading3(false)
      })
  }

  useEffect(() => {
    setLoading4(true)
    axios
      .get(`https://api.testbot.in/admin/get/subscriptionCount`, {
        headers: {
          'X-Custom-Token':
            'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGlUb2tlbiIsIm5hbWUiOiJBa2Fyc2hpdCBOZW1hIiwiaXNzIjoidGVzdGJvdGJhY2tlbmQiLCJ1c2VySWQiOiIyIiwiaWF0IjoxNjc2MjkxODcxLCJqdGkiOiJBa2Fyc2hpdCBOZW1hIn0.U2aha3eh3N7AUQ9UyjKAqmfQbsEbizgE9Ouqrmb8Img',
        },
      })
      .then((res) => {
        let label4fromapi = []
        let subDatafromapi = []

        res.data.data.subscriptionData.map((res) => {
          label4fromapi.push(res.date)
          subDatafromapi.push(res.data)
        })
        setLabel4(label4fromapi)
        setSubData(subDatafromapi)
        setLoading4(false)
      })
  }, [])
  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <div
                  className="d-flex flex-row justify-content-between "
                  style={{ margin: '0 auto', marginRight: '9px' }}
                >
                  <div>
                    <strong> Singup&apos;s</strong>
                  </div>
                  <div className="d-flex flex-row justify-content-end ">
                    <div className="d-flex flex-row ">
                      <CCol sm={7} className="d-none d-md-block">
                        <CButtonGroup
                          className=""
                          style={{ marginLeft: '105px', marginBottom: '20px' }}
                        >
                          <CButton
                            color="outline-secondary"
                            // key={value}
                            className="mx-0"
                            // active={value === 'Month'}
                            onClick={(e) => handelDaily(e)}
                          >
                            Weekly
                          </CButton>
                          <CButton
                            color="outline-secondary"
                            className="mx-0"
                            onClick={(e) => handelMonthly(e)}
                          >
                            Monthly
                          </CButton>
                          <CButton
                            color="outline-secondary"
                            // key={value}
                            className="mx-0"
                            onClick={(e) => handelYearly(e)}
                          >
                            Yearly
                          </CButton>
                        </CButtonGroup>
                      </CCol>
                    </div>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label,
                      datasets: [
                        {
                          label: 'Singup',
                          // backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: '#292D30',
                          pointBorderColor: '3px solid #292D30',
                          data: data,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <div
                  className="d-flex flex-row justify-content-between "
                  style={{ margin: '0 auto', marginRight: '9px' }}
                >
                  <div>
                    <strong>Login Data</strong>
                  </div>
                  <div className="d-flex flex-row justify-content-end ">
                    <div className="d-flex flex-row ">
                      <CCol sm={7} className="d-none d-md-block">
                        <CButtonGroup
                          className=""
                          style={{ marginLeft: '105px', marginBottom: '20px' }}
                        >
                          <CButton
                            color="outline-secondary"
                            // key={value}
                            className="mx-0"
                            // active={value === 'Month'}
                            onClick={(e) => handelWeekly(e)}
                          >
                            Weekly
                          </CButton>
                          <CButton
                            color="outline-secondary"
                            className="mx-0"
                            onClick={(e) => handelMonthlyLogin(e)}
                          >
                            Monthly
                          </CButton>
                          <CButton
                            color="outline-secondary"
                            // key={value}
                            className="mx-0"
                            onClick={(e) => handelYearlyLogin(e)}
                          >
                            Yearly
                          </CButton>
                        </CButtonGroup>
                      </CCol>
                    </div>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading1 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label1,
                      datasets: [
                        {
                          label: 'Login Data',
                          // backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: '#292D30',
                          pointBorderColor: '3px solid #292D30',
                          data: loginData,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <div
                  className="d-flex flex-row justify-content-between "
                  style={{ margin: '0 auto', marginRight: '9px' }}
                >
                  <div>
                    <strong>Test Count</strong>
                  </div>
                  <div className="d-flex flex-row justify-content-end ">
                    <div className="d-flex flex-row ">
                      <CCol sm={7} className="d-none d-md-block">
                        <CButtonGroup
                          role="group"
                          aria-label="Basic checkbox toggle button group"
                          style={{ marginLeft: '105px', marginBottom: '20px' }}
                        >
                          <CFormCheck
                            type="radio"
                            button={{ color: 'secondary', variant: 'outline' }}
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            label="complete"
                            onClick={(e) => setComplete('completed')}
                          />
                          <CFormCheck
                            type="radio"
                            button={{ color: 'secondary', variant: 'outline' }}
                            name="btnradio"
                            id="btnradio2"
                            autoComplete="off"
                            label="started"
                            onClick={(e) => setComplete('started')}
                          />
                          {/* <CButton
                            color="outline-secondary"
                            className="mx-0"
                            onClick={(e) => setComplete('started')}
                          >
                            Started
                          </CButton> */}
                        </CButtonGroup>
                      </CCol>
                    </div>
                  </div>
                </div>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading2 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label2,
                      datasets: [
                        {
                          label: 'TestCount',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#04DC9B',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: ' 3px solid #04DC9B',
                          data: testCount,
                        },
                        {
                          label: 'UniqueUserCount',
                          backgroundColor: '#8884d8',
                          borderColor: '#8884d8',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #8884d8',
                          data: testData,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <div
                  className="d-flex flex-row justify-content-between "
                  style={{ margin: '0 auto', marginRight: '9px' }}
                >
                  <div>
                    <strong>Test Data</strong>
                  </div>
                  <div className="d-flex flex-row justify-content-end ">
                    <div className="d-flex flex-row ">
                      <CCol sm={7} className="d-none d-md-block">
                        <CButtonGroup
                          role="group"
                          aria-label="Basic checkbox toggle button group"
                          style={{ marginLeft: '105px', marginBottom: '20px' }}
                        >
                          <CFormCheck
                            type="radio"
                            button={{ color: 'secondary', variant: 'outline' }}
                            name="btnradio"
                            id="btnradio4"
                            autoComplete="off"
                            label="FULL"
                            onClick={(e) => setCompleteTest('FULL')}
                          />
                          <CFormCheck
                            type="radio"
                            button={{ color: 'secondary', variant: 'outline' }}
                            name="btnradio"
                            id="btnradio5"
                            autoComplete="off"
                            label="RECOMMENDED"
                            onClick={(e) => setCompleteTest('RECOMMENDED')}
                          />
                          <CFormCheck
                            type="radio"
                            button={{ color: 'secondary', variant: 'outline' }}
                            name="btnradio"
                            id="btnradio6"
                            autoComplete="off"
                            label="CUSTOM"
                            onClick={(e) => setCompleteTest('CUSTOM')}
                          />
                        </CButtonGroup>
                      </CCol>
                    </div>
                  </div>
                </div>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit1()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading3 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label3,
                      datasets: [
                        {
                          label: 'Started',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#04DC9B',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: ' 3px solid #04DC9B',
                          data: startedTest,
                        },
                        {
                          label: 'Complete',
                          backgroundColor: '#8884d8',
                          borderColor: '#8884d8',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #8884d8',
                          data: completedTest,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>

          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <div
                  className="d-flex flex-row justify-content-between "
                  style={{ margin: '0 auto', marginRight: '9px' }}
                >
                  <div>
                    <strong>Subscription Count</strong>
                  </div>
                </div>
              </CCardHeader>

              <CCardBody>
                {loading4 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label4,
                      datasets: [
                        {
                          label: 'subcription Count',
                          // backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: '#292D30',
                          pointBorderColor: '3px solid #292D30',
                          data: subData,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
